<template>
  <div>
    <div v-if="level === 0">
      <div class="mb-2"><strong class="mr-2">Taxonomy</strong></div>
      <div class="mb-2">
        <span v-if="!loading">
          <div v-for="value in values" :key="value.id">
            <router-link :to="{ name: 'Taxonomy', params: { id: value.id }}">
              <span v-if="Array.isArray(value.path)">{{value.path.join(' ||| ')}}</span>
              <span v-else>{{value.path}}</span>
            </router-link>
            <b-button variant="outline-primary" size="sm" v-on:click="deleteEdge(value)">remove</b-button>
          </div>
        </span>
        <span v-if="loading">
          <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
        </span>
      </div>
    </div>
    <div>
      <span v-if="!busy">
        <div v-if="selected">
          {{selected.name}}
        </div>
        <div v-if="!selected && options.length > 1">
          <b-form-select class="mb-2" v-model="selected" :options="options" />
        </div>
      </span>
      <span v-if="busy"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
    </div>
    <div class="ml-4" v-if="selected">
      <select-process
        :existingEdges="edgesToPass"
        :level="level + 1"
        :process_parent_id="selected.id"
        :linkingModel="linkingModel"
        :parentId="parentId"
        :parentLinkingField="parentLinkingField"
        :relation="relation"
        :toSave="selected"
        v-on:saveEdgeUp="saveEdge"
      />
    </div>
    <b-button v-if="toSave.id && !selected" class="mb-3" variant="primary" size="sm" v-on:click="saveEdge(toSave)">save</b-button>
    <b-button v-if="selected && level === 0" class="mb-2" variant="outline-primary" size="sm" v-on:click="selected = null">canel</b-button>
  </div>
</template>

<script>
import _ from 'lodash'

const getPath = function (process) {
  if (process.parents.length === 0) {
    return process.name
  } else {
    const result = process.parents.map(x => {
      return getPath(x) + ' > ' + process.name
    })
    return result
  }
}

export default {
  name: 'SelectProcess',
  props: {
    existingEdges: Array,
    level: Number,
    linkable: {
      type: Boolean,
      default: false
    },
    linkingModel: String,
    linkName: {
      type: String,
      default: ''
    },
    parentId: Number,
    parentLinkingField: String,
    process_parent_id: Number,
    propsBased: {
      type: Boolean,
      defualt: false
    },
    relation: String,
    toSave: {
      type: Object,
      default: () => { return { id: false } }
    },
    tableId: String,
    valuesProp: {
      type: Array,
      default: () => []
    },
    verticesProp: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      busy: false,
      edit: false,
      edgesToPass: [],
      loading: true,
      options: [],
      selected: null,
      values: [],
      vertices: []
    }
  },
  created: async function () {
    if (this.level === 0) {
      if (this.propsBased) {
        this.values = this.valuesProp
        this.loading = false
      } else {
        await this.loadEdges()
      }
      this.edgesToPass = this.values
    } else {
      this.edgesToPass = this.existingEdges
    }
    await this.buildOptions()
  },
  methods: {
    buildOptions: async function () {
      this.busy = true
      try {
        let vertices = []
        if (this.level === 0 && this.verticesProp.length > 0) {
          vertices = this.verticesProp
        } else {
          // let response = await this.$http(config)
          vertices = await this.$Amplify.API.get('cosmos', `/taxonomy/parent/${this.process_parent_id}`)
        }
        this.vertices = vertices
        const optionsTemp = this.vertices.map(x => {
          return { id: x.id, value: x, text: x.name }
        })
        if (this.existingEdges.length > 0) {
          this.existingEdges.forEach(x => {
            _.remove(optionsTemp, _.matchesProperty('id', x.id))
          })
        }
        this.options = _.concat([{ value: null, text: 'select' }], _.sortBy(optionsTemp, ['text']))
        this.selected = null
        this.busy = false
      } catch (e) {
        this.$logger.warn('getting vertices error error: ' + e)
      }
    },
    deleteEdge: async function (value) {
      this.$logger.debug('deletePropertyList started')
      this.busy = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/standard/relationship/${this.linkingModel}`
        let params = {
          body: {
            left: { column: this.parentLinkingField, id: this.parentId },
            right: { column: 'taxonomy_id', id: value.id }
          }
        }
        let response = await this.$Amplify.API.del(apiName, path, params)
        this.$stat.log({ action: 'unlinked property', model: this.linkingModel, payload: params.body })
        this.$logger.debug('deleted OK:', response)
        let temp = this.values
        _.pull(temp, value)
        this.values = temp
        this.buildOptions()
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
      this.busy = false
    },
    loadEdges: async function () {
      this.busy = true
      try {
        let response = await this.$Amplify.API.get('cosmos', `/taxonomy/assigned/${this.relation}/${this.tableId}/${this.parentId}`)
        response.forEach(x => {
          x.path = getPath(x)
        })
        response = _.sortBy(response, ['path'])
        this.values = response
        this.loading = false
      } catch (e) {
        console.log(e)
      }
    },
    saveEdge: async function (selected) {
      if (this.level === 0) {
        this.busy = true
        try {
          const params = {
            body: {
              left: { column: this.parentLinkingField, id: this.parentId },
              right: { column: 'taxonomy_id', id: selected.id },
              username: this.user.username
            }
          }
          const response = await this.$Amplify.API.put('cosmos', `/standard/relationship/${this.linkingModel}`, params)
          this.$stat.log({ action: 'linked new property', model: this.linkingModel, model_id: response.id, payload: response })
          selected.path = getPath(selected)
          const newRow = {}
          newRow.id = selected.id
          newRow.path = selected.path
          this.values.push(newRow)
          this.selected = null
          await this.buildOptions()
        } catch (e) {
          console.log(e)
        }
        this.busy = false
      } else {
        this.$emit('saveEdgeUp', selected)
      }
    }
  }
}
</script>

<style>
</style>
