<template>
  <div>
    <div v-if="fields">
      <b-alert show>
        <strong>{{field.label}}</strong>
        <div v-for="field in fields" :key="'jsonb-field-' + field.name" class="mb-4">
          <div v-if="field.type==='input'">
            <b-form-group :id="'jsonb-input-description-group-' + field.name" :label="field.label" :label-for="'jsonb-input-description-' + field.name">
              <b-form-input :id="'jsonb-input-description-' + field.name" v-model="form[field.name]" :placeholder="field.placeholder" :rows="field.rows"></b-form-input>
            </b-form-group>
          </div>
          <div v-if="field.type==='number'">
            <b-form-group :id="'jsonb-input-description-group-' + field.name" :label="field.label" :label-for="'jsonb-input-description-' + field.name">
              <b-form-input :id="'jsonb-input-description-' + field.name" v-model="form[field.name]" type="number"></b-form-input>
            </b-form-group>
          </div>
          <div v-if="field.type==='boolean'">
            <b-form-group :id="'jsonb-input-description-group-' + field.name" :label="field.label" :label-for="'jsonb-input-description-' + field.name">
              <b-form-checkbox :id="'checkbox-description-' + field.name" v-model="form[field.name]" value="true" unchecked-value="false" class="mb-2"></b-form-checkbox>
            </b-form-group>
          </div>
          <div v-if="field.type==='checkbox'">
            <b-form-group :id="'input-description-group-' + field.name" :label="field.label" :label-for="'input-description-' + field.name">
              <b-form-checkbox-group :id="'checkbox-description-' + field.name" v-model="form[field.name]" :options="field.options"></b-form-checkbox-group>
            </b-form-group>
          </div>
          <div v-if="field.type==='select'">
            <b-form-group :id="'input-description-group-' + field.name" :label="field.label" :label-for="'input-description-' + field.name">
              <b-form-select :id="'input-description-' + field.name" v-model="form[field.name]" :options="field.options"></b-form-select>
            </b-form-group>
          </div>
          <div v-if="field.validation" class="mb-4"><small><b-icon-exclamation-circle-fill variant="danger" v-if="!valid.fields[field.name]"></b-icon-exclamation-circle-fill> {{field.validation.text}}</small></div>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { BIconExclamationCircleFill } from 'bootstrap-vue'
export default {
  name: 'JsonbComponent',
  props: ['field', 'set', 'value'],
  components: { BIconExclamationCircleFill },
  computed: {
    fields: function () {
      if (Number.isInteger(parseInt(this.set))) {
        const set = this.field.sets.find(x => x.id === this.set)
        if (set) {
          return set.fields
        } else {
          this.$emit('update:fieldValidation', true)
          return false
        }
      } else {
        this.$emit('update:fieldValidation', true)
        return false
      }
    },
    valid: function () {
      if (this.fields) {
        this.$emit('input', JSON.stringify(this.form))
        const response = {
          fields: {},
          form: false
        }
        const lines = []
        const form = this.form
        let line = true
        this.fields.forEach(field => {
          if ('validation' in field) {
            line = field.validation.method(form[field.name])
          } else {
            line = true
          }
          lines.push(line)
          response.fields[field.name] = line
        })
        response.form = lines.every(x => x === true)
        this.$emit('update:fieldValidation', response.form)
        return response
      } else {
        this.$emit('update:fieldValidation', true)
        return true
      }
    }
  },
  created: function () {
    this.start()
  },
  data () {
    return {
      form: {}
    }
  },
  methods: {
    start: function () {
      if (this.fields) {
        const form = {}
        this.fields.forEach(x => {
          if (this.value) {
            if (this.value[x.name]) {
              form[x.name] = this.value[x.name]
            } else if ('default' in x) {
              form[x.name] = x.default
            }
          } else if ('default' in x) {
            form[x.name] = x.default
          }
          if (x.type === 'checkbox') {
            form[x.name] = _.intersection(form[x.name], x.options)
          }
        })
        this.form = form
      }
      this.$emit('update:fieldValidation', this.valid.form)
    }
  },
  watch: {
    set: function () {
      this.start()
    }
  }
}
</script>

<style>
</style>
